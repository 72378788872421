import { getFullDateTime, parseISO8601Duration } from '~/lib/utilities/datetime'
import { isISO8601Duration } from '~/lib/utilities/regex'
import {
  ColorConfig,
  LabelColor,
  LabelColorConfig,
  SocialLink,
} from '~/types/common'

export const convertEndTime = (time: string) => {
  // covert "23:59:00"/"23:59" to "23:59:59"
  if (!time) return ''

  const timeSplit = time.split(':')
  if (timeSplit.length >= 2) {
    timeSplit[2] = '59'
  }
  return timeSplit.join(':')
}

export const convertFullDateTime = (
  date: string,
  _time?: string,
  isEndTime = false
) => {
  let time: string | undefined
  if (_time) {
    time = isISO8601Duration(_time)
      ? parseISO8601Duration(_time, {
          format: 'hh:mm:ss',
          padLastSecond: isEndTime,
        })
      : isEndTime
      ? convertEndTime(_time)
      : _time
  } else {
    time = isEndTime ? '23:59:59' : '00:00:00'
  }
  return getFullDateTime(date, time)
}

export const convertSocialLink = (data: any): SocialLink => {
  return {
    key: data.Name,
    value: data.Value,
    name: data.Name?.replace('Url', ''),
  }
}

export const convertColorConfig = (data: any): ColorConfig => {
  return {
    textColor: data.TextColor,
    backgroundColor: data.BackgroundColor,
  }
}

export const convertLabelColor = (data: any): LabelColor => {
  return {
    name: data.Name?.toLowerCase(),
    ...convertColorConfig(data),
  }
}

export const convertLabelColorConfig = (data: any): LabelColorConfig => {
  const labels: LabelColorConfig['labels'] = {}
  data.Labels?.forEach((item: any) => {
    const converted = convertLabelColor(item)
    labels[converted.name] = converted
  })
  return {
    default: convertColorConfig(data.Default),
    labels,
  }
}
