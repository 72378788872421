import { Dictionary } from 'ramda'
import { convertCustomer } from './customer'
import { convertStore } from './store'
import { convertProduct } from './product'
import {
  Order,
  OrderLine,
  CustomerOrder,
  ShipmentType,
  OrderPayments,
  OrderPaymentsMethod,
} from '~/types/order'
import { ShippingMethod } from '~/types/cart'

export const convertOrderLine = (
  data: any,
  extraFields?: Dictionary<any>
): OrderLine => {
  const result: OrderLine = {
    id: data.Id,
    priceInclTax: data.PriceInclTax,
    product: convertProduct(data.Product, {
      url: extraFields?.productUrl,
      inMainTree: extraFields?.productInMainTree,
    }),
    productId: data.ProductId,
    productTitle: data.ProductDescription,
    quantity: data.Quantity,
    totalInclTax: data.TotalInclTax,
  }
  result.product.id = result.productId
  result.product.name = result.productTitle
  return result
}

export const convertOrderLines = (
  orderLines: Array<any>,
  productUrls?: Dictionary<string>,
  productsInMainTree?: Dictionary<boolean>
): OrderLine[] => {
  return orderLines.map((orderLine) =>
    convertOrderLine(orderLine, {
      productUrl: productUrls?.[orderLine.ProductId],
      productInMainTree: productsInMainTree?.[orderLine.ProductId],
    })
  )
}

export const convertOrderPaymentsMethod = (data: any): OrderPaymentsMethod => ({
  id: data.Id,
  code: data.Code,
  description: data.Description,
  sortOrder: data.SortOrder,
  paymentCosts: data.PaymentCosts,
})

export const convertOrderPayment = (data: any): OrderPayments => ({
  id: data.Id,
  customerOrderId: data.CustomerOrderId,
  amount: data.Amount,
  paymentMethodId: data.PaymentMethodId,
  paymentMethod: convertOrderPaymentsMethod(data.PaymentMethod),
})

export const convertOrderPayments = (data: any): OrderPayments[] => {
  return data.map((paymentItem) => convertOrderPayment(paymentItem))
}

export const convertOrder = (data: any, extraFields: any): Order => {
  const order: Order = {
    id: data.Id,
    orderNumber: data.OrderNumber,

    shippingMethod: data.IsDeliveryOrder
      ? ShippingMethod.Delivery
      : ShippingMethod.Pickup,
    preferredDeliveryTimeFrom: data.PreferredDeliveryTimeFrom,
    preferredDeliveryTimeTo: data.PreferredDeliveryTimeTo,
    deliveryTime: data.DeliveryTime,
    deliveryAddress: {
      street: data.Delivery_Street,
      city: data.Delivery_City,
      houseNumber: data.Delivery_HouseNumber,
      postalCode: data.Delivery_PostalCode,
      countryCode: data.Delivery_CountryCode,
    },

    bonusPoints: extraFields?.BonusPoints,

    orderLines: convertOrderLines(data.OrderLines),
    shippingPrice: extraFields?.ShippingPrice,
    voucherTotal: extraFields?.VoucherTotal,
    totalExclTax: data.TotalExclTax,
    totalInclTax: data.TotalInclTax,
  }

  if (data.PreferredDeliveryDate) {
    order.preferredDeliveryDate = new Date(data.PreferredDeliveryDate)
  }
  if (data.DeliveryDate) {
    order.deliveryDate = new Date(data.DeliveryDate)
  }

  if (data.Store) {
    order.store = convertStore(data.Store)
    order.store.url = extraFields?.StoreUrl
  }

  if (data.Customer) {
    order.customer = convertCustomer(data.Customer)
  }

  return order
}

export const convertCustomerOrder = (
  data: any,
  productUrls?: Dictionary<string>,
  productsInMainTree?: Dictionary<boolean>
): CustomerOrder => {
  const order: CustomerOrder = {
    id: data.Id,
    orderNumber: data.OrderNumber,
    status: data.Status,

    shippingMethod: data.IsDeliveryOrder
      ? ShippingMethod.Delivery
      : ShippingMethod.Pickup,
    orderDate: data.OrderDate,
    deliveryAddress: data.IsDeliveryOrder
      ? {
          street: data.Delivery_Street,
          city: data.Delivery_City,
          houseNumber: data.Delivery_HouseNumber,
          postalCode: data.Delivery_PostalCode,
          countryCode: data.Delivery_CountryCode,
        }
      : undefined,

    totalExclTax: data.TotalExclTax,
    totalInclTax: data.TotalInclTax,

    shipmentTypeId: data.ShipmentTypeId,

    invoiceAddressId: data.InvoiceAddressId,

    orderLines: convertOrderLines(
      data.OrderLines ?? [],
      productUrls,
      productsInMainTree
    ),
    payments: convertOrderPayments(data.Payments ?? []),
  }

  if (data.OrderDate) {
    order.orderDate = new Date(data.OrderDate)
  }

  if (data.Store) {
    order.store = convertStore(data.Store)
  }

  if (data.Payments) {
    order.payments = convertOrderPayments(data.Payments)
  }

  return order
}

export const convertCustomerOrders = (
  orders: Array<any>,
  productUrls?: Dictionary<string>,
  productsInMainTree?: Dictionary<boolean>
): CustomerOrder[] => {
  return orders.map((orderLine) =>
    convertCustomerOrder(orderLine, productUrls, productsInMainTree)
  )
}

export const convertShipmentType = (data: any): ShipmentType => ({
  id: data.Id,
  description: data.Description,
})

export const convertShipmentTypes = (
  shipmentTypes: Array<any>
): ShipmentType[] => {
  return shipmentTypes.map((orderLine) => convertShipmentType(orderLine))
}
