
const hashRequest = (config: any) => {
  const json = JSON.stringify({
    url: config.url,
    params: config.params,
    data: config.data
  })

  let hash = 0;
  let i = 0;
  let chr = 0;
  if (json.length === 0) return hash;
  for (i = 0; i < json.length; i++) {
    chr   = json.charCodeAt(i);
    hash  = ((hash << 5) - hash) + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
}

export const addCache = ({
  $axios,
  $config,
  ssrContext,
  urlsToCache
}) => {
  urlsToCache ??= []

  if (!process.client && $config.AXIOS_CACHE_EXPIRE_MINS) {
    const oldAdapter =  $axios.defaults.adapter
    if (oldAdapter) {
      $axios.defaults.adapter = config => {
        if (config.url && urlsToCache.includes(config.url)) {
          const cache = (ssrContext as any).$axiosCache
          const cacheKey = hashRequest(config)
          const response = cache.get(cacheKey)
          if (response) {
            return Promise.resolve(response)
          }
        }

        return oldAdapter(config)
      }
    }
  }

  $axios.interceptors.request.use(config => {
    // SSR API Log
    if (!process.client) {
      // eslint-disable-next-line no-console

      const anyConfig = config as any
      anyConfig.meta = {
        requestStartTime: new Date().getTime()
      }
    }
    return config
  })

  $axios.interceptors.response.use(response => {
    // SSR API Log
    const axiosConfig = response.config as any
    if (!process.client && $config.AXIOS_CACHE_EXPIRE_MINS) {
      let apiLog: string|null = null

      if (axiosConfig.meta.cached) {
        apiLog = `api>(from cache): ${response.config.url}`
      } else {
        const cost = new Date().getTime() - (response.config as any).meta.requestStartTime
          apiLog = `api>(${cost}): ${response.config.url}`

        if (response.status === 200) {
          const url = response.config.url
          if (url && urlsToCache.includes(url)) {
            const cache = (ssrContext as any).$axiosCache

            apiLog = `api>(${cost}->cache): ${response.config.url}`
            axiosConfig.meta.cached = true

            const cacheKey = hashRequest(response.config)
            cache.set(cacheKey, response)
          }
        }
      }

      if (apiLog) {
        // eslint-disable-next-line no-console
        console.log(apiLog)
      }
    }

    return response
  }, error => {
    // eslint-disable-next-line no-console
    console.log(`api>(error->${error.response.status}): ${error.config.url}`)
    return Promise.reject(error)
  })

}


